


























import { Prop, Component, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'change',
  },
})
export default class RadioInputBox extends Vue {
  @Prop() value: any;
  @Prop() modelValue: any;
  @Prop({ default: false }) hideBox: boolean;
  @Prop({ default: 'radio-input-box' }) name: string;
  get isChecked() {
    return JSON.stringify(this.modelValue) === JSON.stringify(this.value);
  }

  emitInput() {
    this.$emit('change', this.value);
  }
}

import MainContent from './index.vue';
import { shallowMount } from '@vue/test-utils';

jest.mock('@/shared/helpers/toast');
jest.mock('@/services');
jest.mock('@/router', () => ({
  currentRoute: {
    name: 'MockRoute',
  },
}));

describe('mainContent', () => {
  let wrapper;
  const stubs = {
    HsGroup: true,
    HsInput: true,
    HsRadio: true,
    HsCheckbox: true,
    HsIcon: true,
    HsButton: {
      name: 'hs-button',
      template: '<button class="hsbutton-stub"></button>',
    },
    BCollapse: true,
    HsMultiselect: true,
  };

  const propsData = {
    activity: '',
  };

  beforeEach(() => {
    wrapper = mountWrapper(stubs, propsData);
  });

  describe('behaviour', () => {
    describe('when change type selector', () => {
      beforeEach(() => {
        wrapper.setData({ selectedType: { id: 1 } });
      });
      it('should update mode to media-input', () => {
        expect(wrapper.vm.mode).toBe('type-selection');

        wrapper.find('#type-selector').vm.$emit('select', { id: '1' });

        expect(wrapper.vm.mode).toBe('media-input');
      });
    });
    describe('when has activity', () => {
      it('should change mode to activity-edition', () => {
        expect(wrapper.vm.mode).toBe('type-selection');

        wrapper.setProps({ activity: { id: 1 } });

        expect(wrapper.vm.mode).toBe('activity-edition');
      });
    });
  });
});

function mountWrapper(stubs, propsData) {
  return shallowMount(MainContent, {
    stubs,
    propsData,
    mocks: {
      $t: jest.fn(),
    },
  });
}
